import React, { useState, useEffect } from 'react';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";

import './home.css';
import audioData from "../../data/AudioBank.json";
import Header from "../../components/header/Header";
import Footer from '../../components/footer/Footer';
import { Calender } from '../../components/Calender/CalenderApp/Calender';
import PopupMessage from '../../components/startPopup/PopupMessage';

export default function Home() {
  // Get the current month
  const currentMonthIndexFromCurrentDate = new Date().getMonth();
  const [currentMonthIndex, setCurrentMonthIndex] = useState(currentMonthIndexFromCurrentDate);

  const totalMonths = Object.keys(audioData).length;

  // Load background image for the current month
  useEffect(() => {
    const backgroundImage = document.querySelector('.background-image');
    if (backgroundImage) {
      const currentMonth = Object.keys(audioData)[currentMonthIndex];
      backgroundImage.src = `/assets/img/${currentMonth.toLowerCase()}-background.webp`;
    }
  }, [currentMonthIndex]);

  // Navigate to next month
  const goNextMonth = () => {
    if (currentMonthIndex < totalMonths - 1) {
      setCurrentMonthIndex((prevIndex) => prevIndex + 1);
    }
  };
  
  // Navigate to next month with block after current month
  /*const goNextMonth = () => {
    const currentDate = new Date();
    const currentDayOfMonth = currentDate.getDate();
    const targetMonthFirstDay = new Date(currentDate.getFullYear(), currentMonthIndex + 1, 1);

    if (currentDate >= targetMonthFirstDay) {
      if (currentMonthIndex < totalMonths - 1) {
        setCurrentMonthIndex((prevIndex) => prevIndex + 1);
      }
    }
  };*/

  // Navigate to previous month
  const goPreviousMonth = () => {
    if (currentMonthIndex > 0) {
      setCurrentMonthIndex((prevIndex) => prevIndex - 1);
    }
  };

  const isOnFirstMonth = currentMonthIndex === 0;
  /*const isOnLastMonth = currentMonthIndex === currentMonthIndexFromCurrentDate;*/
  const isOnLastMonth = currentMonthIndex === totalMonths - 1;

  // Audio part
  const currentMonthData = audioData[Object.keys(audioData)[currentMonthIndex]];

  const [audioVolumes, setAudioVolumes] = useState({
    audio1: 50,
    audio2: 50,
    audio3: 50,
    audio4: 50
  });

  const [defaultAudioPaused, setDefaultAudioPaused] = useState(true);
  const [audioPaused, setAudioPaused] = useState(defaultAudioPaused);

  // Play Pause function for apple mobile devices
  const isAppleDevice = () => /iPad|iPhone|iPod|Mac/.test(navigator.userAgent);
  const isMobileView = () => isAppleDevice() && window.innerWidth <= 768;
  const [audioPlayState, setAudioPlayState] = useState({
    audio1: true,
    audio2: true,
    audio3: true,
    audio4: true
  });
  const toggleAudio = (audioId) => {
    const audioElement = document.getElementById(audioId);
  
    if (audioElement) {
      if (audioElement.paused) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
      setAudioPlayState((prevPlayState) => ({
        ...prevPlayState,
        [audioId]: audioElement.paused
      }));
    }
  };
  
  // Volume controlling
  const handleAudioVolumeChange = (event, audioId) => {
    const newVolume = event.target.value;
    setAudioVolumes((prevVolumes) => ({ ...prevVolumes, [audioId]: newVolume }));
  
    const audioElement = document.getElementById(audioId);
    if (audioElement) {
      audioElement.volume = newVolume / 100;
  
      if (newVolume > 0 && audioPaused) {
        setAudioPaused(true);
      }
    }
  };

  // Play Pause button
  const playAudio = () => {
    const audioElements = Array.from({ length: 4 }, (_, i) => document.getElementById(`audio${i + 1}`));

    if (defaultAudioPaused) {
      audioElements.forEach((audioElement) => audioElement.play());
    } else {
      audioElements.forEach((audioElement) => audioElement.pause());
    }

    setDefaultAudioPaused(!defaultAudioPaused);
    setAudioPaused(!defaultAudioPaused);
  };

  // Changes for sliders
  useEffect(() => {
    /*setAudioVolumes({
      audio1: 50,
      audio2: 50,
      audio3: 50,
      audio4: 50
    });*/
    
    if (!defaultAudioPaused) {
      playAudio();
    }
  }, [currentMonthIndex]);

  // Audio container slide and hide
  const [audioContainerHidden, setAudioContainerHidden] = useState(false);

  const toggleAudioContainer = () => {
    setAudioContainerHidden(!audioContainerHidden);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAudioContainerHidden(true);
    }, 60000);
    return () => clearTimeout(timeoutId);
  }, [audioContainerHidden]);

  // Calendar popup
  const [showCalendar, setShowCalendar] = useState(false);

  const handleCalendarIconClick = () => {
    setShowCalendar(!showCalendar);
    setAudioContainerHidden(true);
  };

  const handleCloseButtonClick = () => {
    setShowCalendar(false);
    setAudioContainerHidden(false);
  };

  // Start popup message
  const localStorageKey = 'hasVisitedBefore';
  const [isFirstVisit, setIsFirstVisit] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem(localStorageKey)) {
      setIsFirstVisit(true);
      localStorage.setItem(localStorageKey, 'true');
    }
  }, []);

  return (
    <div>
      <img className='background-image' src={`/assets/img/${Object.keys(audioData)[currentMonthIndex].toLowerCase()}-background.png`} alt={Object.keys(audioData)[currentMonthIndex]} />
      <div>
        {isFirstVisit && <PopupMessage onClose={() => setIsFirstVisit(false)} />}
      </div>
      {showCalendar ? (
        <div>
          <p className='calender-close' onClick={handleCloseButtonClick}><IoIosCloseCircleOutline /></p>
          <div className='overlay'></div>
          <Calender />
        </div>
      ) : null}
        <div>
          {showCalendar ? (
            <div className='space'></div>
          ) : (
            <Header
            goNextMonth={goNextMonth}
            goPreviousMonth={goPreviousMonth}
            isOnFirstMonth={isOnFirstMonth}
            isOnLastMonth={isOnLastMonth}
          />
          )}
          <div className='content'>
            <button className='slide-btn' onClick={goPreviousMonth} disabled={isOnFirstMonth}><MdNavigateBefore /></button>
            <div className='row slide-container'>
              <div className={`audio-container ${audioContainerHidden ? 'hide-audio-container' : ''}`}>
                <div className='audio-controler'>
                  <img
                    className='audioicon playbutton'
                    src={audioPaused ? "/assets/icons/play-audio.png" : "/assets/icons/pause-audio.png"}
                    alt={audioPaused ? "play" : "pause"}
                    onClick={playAudio}
                  />
                </div>
                {currentMonthData.audioData.map((audio) => (
                  <div className='audio-controler' key={audio.id}>
                    <audio id={`audio${audio.id}`} src={audio.audio} loop></audio>
                    <img
                      className={`audioicon icon-items ${audioPlayState[`audio${audio.id}`] ? 'paused' : ''}`}
                      src={audio.image} alt={audio.alt}
                      onClick={() => isMobileView() && toggleAudio(`audio${audio.id}`)}
                    />
                    <input
                      className='volume-slider'
                      type='range'
                      min='0'
                      max='100'
                      value={audioVolumes[`audio${audio.id}`]}
                      onChange={(event) => handleAudioVolumeChange(event, `audio${audio.id}`)}
                    />
                  </div>
                ))}
              </div>
              <div className={`icon-container ${audioContainerHidden ? 'hide-icon-container' : ''}`} onClick={toggleAudioContainer} audioContainerHidden={audioContainerHidden}>
                {audioContainerHidden ? <MdNavigateBefore /> : <MdNavigateNext />}
              </div>
            </div>
            <button onClick={goNextMonth} disabled={isOnLastMonth}><MdNavigateNext /></button>
          </div>
          {showCalendar ? (
            <div className='space'></div>
          ) : (
          <Footer
              onCalendarIconClick={handleCalendarIconClick}
              currentMonth={Object.keys(audioData)[currentMonthIndex]}
            />
          )}
        </div>

    </div>
  );
};